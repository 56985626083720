import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import * as zimranAnalytics from "@zimran/analytics";
import { IUserGeoData } from "@/src/shared/types/user";

const useAnalytics = () => {
    const { query, isReady } = useRouter();
    const [queryParams, setQueryParams] = useState<Record<string, any>>({});
    const [ready, setReady] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (!isReady) return;

        const filteredQueryParams: Record<string, any> = {};

        for (const [param, value] of Object.entries(query)) {
            const loweredParam = param.toLowerCase();
            if (!value) continue;

            filteredQueryParams[loweredParam] = value;
        }

        setQueryParams(filteredQueryParams);
        setUserProperties(filteredQueryParams);
        setReady(true);
    }, [isReady]);

    const init = (userGeoData?: IUserGeoData, deviceId?: string) => {
        let userId: string | undefined;

        zimranAnalytics.init(
            {
                credentials: {
                    accessKeyId:
                        process.env.NEXT_PUBLIC_SENTINEL_ACCESS_KEY || "",
                    secretAccessKey:
                        process.env.NEXT_PUBLIC_SENTINEL_SECRET_KEY || "",
                },
                region: process.env.NEXT_PUBLIC_SENTINEL_REGION || "",
                deliveryStreamName:
                    process.env.NEXT_PUBLIC_SENTINEL_DELIVERY_STREAM || "",
            },
            zimranAnalytics.AnalyticsSource.FUNNEL,
            {
                deviceId: deviceId,
                userId,
                city: userGeoData?.city,
                region: userGeoData?.region,
                country_code: userGeoData?.country_code,
                ipAddress: userGeoData?.ip_addr,
                attribution: {
                    endpointUrl: `${process.env.NEXT_PUBLIC_SENTINEL_URL}`,
                    userToken: process.env.FUNNEL_TOKEN ?? "",
                },
            }
        );
    };

    const setUserProperties = (userProperties: Record<string, any>) => {
        zimranAnalytics.setUserProperties(userProperties);
    };

    const trackEvent = async (
        eventName: string,
        eventProperties?: Record<string, any>,
        isSetUserProperties = true
    ) => {
        const search = window.location.search;

        const properties: Record<string, any> = {
            ...queryParams,
            ...eventProperties,
            language: router.locale || "",
        };

        if (isSetUserProperties) {
            setUserProperties(properties);
        }

        return await zimranAnalytics.sendEvent(eventName, properties);
    };

    const setUserId = (userId: number) => {
        zimranAnalytics.setUserId(userId);
    };

    return {
        init,
        trackEvent,
        setUserProperties,
        setUserId,
        getAnalyticsDeviceId: zimranAnalytics.getDeviceId,
        getAttributionId: zimranAnalytics.getAttributionId,
        queryParams,
        ready,
    };
};

export default useAnalytics;
