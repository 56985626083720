import { FC, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import useAnalytics from "@/src/shared/hooks/analytics";
import { useRouter } from "next/router";
import { getUserGeoData } from "@/src/shared/utils/cookies";
import * as Pixel from "@zimran/pixel";
import Cookies from "js-cookie";
import { usePixelBundleId } from "../../hooks/pixelBundleId";

interface Props {
    children: JSX.Element | JSX.Element[];
}

const AnalyticsRoot: FC<Props> = ({ children }) => {
    const [isPixelReady, setIsPixelReady] = useState(false);
    const analytics = useAnalytics();
    const router = useRouter();
    const pixelBundleId = usePixelBundleId();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const userGeoData = getUserGeoData();
        analytics.init(
            userGeoData,
            router.query.device_id as string | undefined
        );
        if (process.env.NEXT_PUBLIC_CLARITY_ID)
            clarity.init(process.env.NEXT_PUBLIC_CLARITY_ID);
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (pixelBundleId) {
            void Pixel.init(
                `${process.env.NEXT_PUBLIC_GATEWAY_URL}/conversions` || "",
                pixelBundleId
            );
            setTimeout(() => {
                analytics.setUserProperties({
                    pixel_bundle_id: pixelBundleId,
                    fbc: Cookies.get("_fbc"),
                    fbp: Cookies.get("_fbp"),
                });
                setIsPixelReady(true);
            }, 1000);
        }
    }, [pixelBundleId]);

    useEffect(() => {
        if (!router.isReady || !isPixelReady) return;
        Pixel.sendEvent("pageView", { page: { path: router.pathname } });
    }, [router.pathname, router.isReady, isPixelReady]);

    return <>{isInitialized ? children : null}</>;
};

export default AnalyticsRoot;
