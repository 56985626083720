import { IUserGeoData } from "@/src/shared/types/user";
import Cookies from "js-cookie";

export const getUserGeoData = () => {
    const userGeoData = {} as IUserGeoData;
    userGeoData.city = Cookies.get("city") || "";
    userGeoData.region = Cookies.get("region") || "";
    userGeoData.country_code = Cookies.get("country_code") || "";
    userGeoData.ip_addr = Cookies.get("ip_addr") || "";

    return userGeoData;
};
