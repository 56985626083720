import React from "react";
import { RecoilRoot } from "recoil";
import "../styles/globals.css";
import "../styles/prspri.css";
import type { AppProps } from "next/app";

import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import "@/src/shared/utils/vh-optimizer/vh.css";
import AnalyticsRoot from "@/src/shared/components/shared/AnalyticsRoot";

const MyApp = ({ Component, pageProps }: AppProps) => {
    return (
        <>
            <Head>
                <title>Finelo</title>
                <link rel="icon" href="favicon.ico" />
                <link rel="dns-prefetch" href="https://quiz.finelo.com" />
                <meta
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    name="viewport"
                />
            </Head>
            <RecoilRoot>
                <AnalyticsRoot>
                    <Component {...pageProps} />
                </AnalyticsRoot>
            </RecoilRoot>
        </>
    );
};

export default appWithTranslation(MyApp);
